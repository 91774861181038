// Generated by Framer (013b13c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["kyWIM5B5j", "vXRE2ym9U", "brXdpo_Wt"];

const serializationHash = "framer-90QGF"

const variantClassNames = {brXdpo_Wt: "framer-v-16cipb6", kyWIM5B5j: "framer-v-110jlny", vXRE2ym9U: "framer-v-tknlth"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Big: "vXRE2ym9U", Medium: "kyWIM5B5j", Small: "brXdpo_Wt"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, r4IsvrEYT: image ?? props.r4IsvrEYT ?? {src: "https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png?scale-down-to=512 512w,https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png 1920w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kyWIM5B5j"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string; alt?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, r4IsvrEYT, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kyWIM5B5j", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-110jlny", className, classNames)} data-framer-name={"Medium"} layoutDependency={layoutDependency} layoutId={"kyWIM5B5j"} ref={refBinding} style={{backgroundColor: "rgb(138, 123, 227)", borderBottomLeftRadius: 14, borderBottomRightRadius: 14, borderTopLeftRadius: 14, borderTopRightRadius: 14, ...style}} {...addPropertyOverrides({brXdpo_Wt: {"data-framer-name": "Small"}, vXRE2ym9U: {"data-framer-name": "Big"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 8 + 0)), sizes: "319px", ...toResponsiveImage(r4IsvrEYT)}} className={"framer-57cjwa"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"MiwO3FHBS"} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}} {...addPropertyOverrides({brXdpo_Wt: {background: {alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 4 + 0)), sizes: "226px", ...toResponsiveImage(r4IsvrEYT)}}, vXRE2ym9U: {background: {alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 8 + 0)), sizes: "360px", ...toResponsiveImage(r4IsvrEYT)}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-90QGF.framer-1pmazj9, .framer-90QGF .framer-1pmazj9 { display: block; }", ".framer-90QGF.framer-110jlny { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 8px; position: relative; width: min-content; }", ".framer-90QGF .framer-57cjwa { aspect-ratio: 1.782122905027933 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 179px); position: relative; width: 319px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-90QGF.framer-110jlny { gap: 0px; } .framer-90QGF.framer-110jlny > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-90QGF.framer-110jlny > :first-child { margin-top: 0px; } .framer-90QGF.framer-110jlny > :last-child { margin-bottom: 0px; } }", ".framer-90QGF.framer-v-tknlth .framer-57cjwa { height: var(--framer-aspect-ratio-supported, 202px); width: 360px; }", ".framer-90QGF.framer-v-16cipb6.framer-110jlny { padding: 4px; }", ".framer-90QGF.framer-v-16cipb6 .framer-57cjwa { height: var(--framer-aspect-ratio-supported, 127px); width: 226px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 195
 * @framerIntrinsicWidth 335
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"vXRE2ym9U":{"layout":["auto","auto"]},"brXdpo_Wt":{"layout":["auto","auto"]}}}
 * @framerVariables {"r4IsvrEYT":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbxDqCosHX: React.ComponentType<Props> = withCSS(Component, css, "framer-90QGF") as typeof Component;
export default FramerbxDqCosHX;

FramerbxDqCosHX.displayName = "Helper/ Image Container 5";

FramerbxDqCosHX.defaultProps = {height: 195, width: 335};

addPropertyControls(FramerbxDqCosHX, {variant: {options: ["kyWIM5B5j", "vXRE2ym9U", "brXdpo_Wt"], optionTitles: ["Medium", "Big", "Small"], title: "Variant", type: ControlType.Enum}, r4IsvrEYT: {__defaultAssetReference: "data:framer/asset-reference,2nWAA3krw8qkTjDMGMwF7IpDJo.png?originalFilename=Rectangle+543468.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerbxDqCosHX, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})